import AmazonLogo from "../../images/Amazonlogo.png";
import FlipkartLogo from "../../images/flipkart-logo.png";
import MyntraLogo from "../../images/myntra-logo.png";
import MeeshoLogo from "../../images/Meesho_logo.png";
import NykaaLogo from "../../images/nykaa-Capital-Mall.png";
import ShopifyLogo from "../../images/shopify-logo.png";
import JiomartLogo from "../../images/JioMart_logo.svg.png";
import TallyLogo from "../../images/talley.webp";
import ZohoLogo from "../../images/zoho.avif";
import MSDynamicsLogo from  "../../images/ms.png";
import SapLogo from "../../images/sap.png";
import DtdcLogo from "../../images/dtdc.avif";
import ShiprocketLogo from "../../images/ship.png";
import DelhiveryLogo from "../../images/delhi.jpg";
import RazorPayLogo from "../../images/Razorpay_logo.png";
import CashFreeLogo from "../../images/cashfree.png";


 const MarketPlaceServices=[
    {
        name:"Amazon",
        logo: AmazonLogo
    },
    {
        name:"Flipkart",
        logo:FlipkartLogo
    },
    {
        name:"Myntra",
        logo:MyntraLogo
    },
    {
        name:"Meesho",
        logo:MeeshoLogo
    },
    {
        name:"Nykaa",
        logo:NykaaLogo
    },
    {
        name:"Shopify",
        logo:ShopifyLogo
    },
    {
        name:"Jiomart",
        logo:JiomartLogo
    }
];

const ErpServices =[
    {
        name: "Tally",
        logo: TallyLogo
    },
    {
        name: "MS dynamics",
        logo: MSDynamicsLogo
    },
    {
        name: "Zoho",
        logo: ZohoLogo
    },
    {
        name: "SAP",
        logo: SapLogo
    },
]

const LogisticsServices= [
{
    name:"DTDC",
    logo: DtdcLogo
},

{
    name:"Shiprocket",
    logo: ShiprocketLogo
},
{
    name:"Delhivery",
    logo: DelhiveryLogo
} 
]

const PaymentGatewayServices=[
    {
        name:"Cashfree",
        logo: CashFreeLogo
    },
    {
        name:"Razorpay",
        logo:RazorPayLogo
    }
]

export  {ErpServices,MarketPlaceServices, LogisticsServices, PaymentGatewayServices};