import { useState } from "react";
import { generateEmailTemplate, sendEmail } from "../utils";
import { DatePicker, Input, Modal, Select } from "antd";
import moment from "moment";

export const DemoModal = ({ showModal, onClose, heading }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyname: "",
    marketplaces: "",
    dateTime: null,
  });

  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async() => {
    const response = await sendEmail(formData);
    if(response){
      alert("Email sent Successfully!")
    }
    else{
      alert("Please try after sometime")

    }
    onClose()
  };

  return (
    <Modal
      title={heading}
      open={showModal}
      onOk={handleSubmit}
      onCancel={onClose}
    >
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Input
          placeholder="Full Name"
          style={{ marginTop: "10px" }}
          value={formData.name}
          onChange={(e) => handleChange("name", e.target.value)}
        />
        <Input
          placeholder="Business Email"
          style={{ marginTop: "10px" }}
          value={formData.email}
          onChange={(e) => handleChange("email", e.target.value)}
        />
        <Input
          placeholder="Phone Number"
          style={{ marginTop: "10px" }}
          value={formData.phone}
          onChange={(e) => handleChange("phone", e.target.value)}
        />
        <Input
          placeholder="Company Name"
          style={{ marginTop: "10px" }}
          value={formData.companyname}
          onChange={(e) => handleChange("companyname", e.target.value)}
        />
        <Select
          style={{ marginTop: "10px", width: "100%" }}
          value={formData.marketplaces}
          onChange={(value) => handleChange("marketplaces", value)}
          options={[
            { value: "amazon", label: "Amazon" },
            { value: "flipkart", label: "Flipkart" },
            { value: "myntra", label: "Myntra" },
            { value: "meesho", label: "Meesho" },
            { value: "nykaa", label: "Nykaa" },
            { value: "shopify", label: "Shopify" },
            { value: "jiomart", label: "Jiomart" },
            { value: "tally", label: "Tally" },
            { value: "zoho", label: "ZOHO" },
          ]}
        />
        <DatePicker
          style={{ marginTop: "10px" }}
          showTime
          value={formData.dateTime ? moment(formData.dateTime) : null}
          onChange={(date) => handleChange("dateTime", date)}
        />
      </div>
    </Modal>
  );
};
