import React from "react";
import "./Testimonials.css";
import { TestimonialData } from "./TestimonialData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';

const Testimonials = () => {
    return (
        <div id="testimonial" className="testimonials container-fluid">
            <div className="container">
                <div className="row">
                    <div className="heading col-12 col-12 col-sm-12 col-md-12 col-lg-8 mx-auto">
                        <h5>Our Testimonials</h5>
                        <div className="sep-element primary" />
                    </div>
                    <div className="col-12">
                        <Swiper slidesPerView={'auto'} spaceBetween={30}
                            pagination={{ clickable: true, }}
                            modules={[Pagination]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,  // On small screens (mobile), show one slide
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 1,  // On medium screens (tablets), show two slides
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 2,  // On large screens (desktops), show three slides
                                    spaceBetween: 40,
                                },
                                1200: {
                                    slidesPerView: 2,  // On extra-large screens (larger desktops), keep three slides
                                    spaceBetween: 50,
                                },
                            }}
                            className="mySwiper"
                        >
                            {TestimonialData.map((testimonial, index) => (
                                <SwiperSlide>
                                    <div className="col-12">
                                        <div className="row mx-0 testimonials-card" data-aos="zoom-out">
                                            <div className="col-12">
                                                <p>{testimonial.description}</p>
                                            </div>
                                        </div>
                                        <div className="testimonials-client-details">
                                            <div className="client-img">
                                                <img className="img-fluid" alt="" src="https://guardteam.true-emotions.studio/wp-content/uploads/2017/01/t2.jpg" />
                                            </div>
                                            <div className="client-details-inner">
                                                <div id="image-caption">
                                                    <h5>{testimonial.name}
                                                        <span>({testimonial.designation})</span></h5>
                                                </div>
                                                <div className="review">
                                                    <h6>{testimonial.review}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Testimonials;
