import axios from "axios";

export const sendEmail = async (message) => {
  // try {
  //   const response = await axios.post(
  //     "https://api.sendgrid.com/v3/mail/send",
  //     {
  //       personalizations: [{ to: [{ email: "mangalsanchit20@gmail.com" }] }],
  //       from: { email: "info@deepecom.com" },
  //       subject: "New Lead",
  //       content: [{ type: "text/html", value: message }],
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer 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`,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );

  //   console.log("Email sent successfully", response.data);
  // } catch (error) {
  //   console.error("Failed to send email", error);
  // }
  try {
    const response = await axios.post(
      "https://dashboard.deepecom.com/api/contact",
      message,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if(response){
      return true
    }
    return false
  } catch (error) {
    console.error("Error sending contact details:", error);
    return false

  }
};

export const generateEmailTemplate = (data) => {
  return `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: #f4f4f4;
              padding: 20px;
            }
            .container {
              max-width: 600px;
              margin: auto;
              background: #fff;
              padding: 20px;
              border-radius: 10px;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            }
            h2 {
              color: #333;
              text-align: center;
            }
            .info {
              padding: 10px;
              border-bottom: 1px solid #ddd;
            }
            .info:last-child {
              border-bottom: none;
            }
            .label {
              font-weight: bold;
              color: #555;
            }
            .value {
              color: #333;
            }
            .footer {
              text-align: center;
              margin-top: 20px;
              font-size: 12px;
              color: #777;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h2>New Demo Booking</h2>
            <div class="info">
              <span class="label">Full Name:</span> <span class="value">${
                data.fullName
              }</span>
            </div>
            <div class="info">
              <span class="label">Business Email:</span> <span class="value">${
                data.businessEmail
              }</span>
            </div>
            <div class="info">
              <span class="label">Phone Number:</span> <span class="value">${
                data.phoneNumber
              }</span>
            </div>
            <div class="info">
              <span class="label">Company Name:</span> <span class="value">${
                data.companyName
              }</span>
            </div>
            <div class="info">
              <span class="label">Platform:</span> <span class="value">${
                data.platform
              }</span>
            </div>
            <div class="info">
              <span class="label">Date & Time:</span> <span class="value">${new Date(
                data.dateTime
              ).toLocaleString()}</span>
            </div>
            <div class="footer">
              This is an automated message. Please do not reply.
            </div>
          </div>
        </body>
      </html>
    `;
};
