import React from "react";
import "./Client.css";
import { TestimonialData } from "./ClientData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Clients = () => {
  return (
    <div id="client" className="clients container-fluid">
      <div className="container">
        <div className="row">
          <div className="heading col-12 col-12 col-sm-12 col-md-12 col-lg-8 mx-auto">
            <h5>Our Clients</h5>
            <div className="sep-element primary" />
          </div>
          <div className="col-12 clientImagesRow">
            <div>
            <img
              src="https://static.wixstatic.com/media/3877f7_5eae9490d81e4ef4b5db985d38aa734c~mv2.png/v1/fill/w_980,h_557,al_c,q_90,usm_0.66_1.00_0.01,enc_avif,quality_auto/Lykis%20Brand.png"
              height="100"
              width="170"
            ></img>
            </div>
<div>
<img
              src="https://www.allcargogati.com/assets/images/logo.svg"
              height="100"
              width="170"
            ></img>
</div>
<div>
<img
              src="https://upload.wikimedia.org/wikipedia/en/thumb/7/7b/Hinduja_Group_Logo.svg/1200px-Hinduja_Group_Logo.svg.png"
              height="100"
              width="100"
            ></img>
</div>
 <div>
 <img
              src="https://assets.upstox.com/content/assets/images/cms/202451/Amazon%20logo.png"
              height="100"
              width="200"
            ></img>
 </div>
 <div>
 <img
              src="https://www.paragongeysers.com/Content/assets/images/layout-5/logo/logo.png"
              height="80"
              width="250"
            ></img>
 </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
