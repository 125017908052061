import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import { Space } from "antd";

const Privacy = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <>
      <div
        className="container-fluid"
        style={{ marginTop: "150px", background: "white" }}
      >
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>
            <strong>Effective Date:</strong> 05-04-2025
          </p>

          <p>
            DeepEcom ("we," "our," or "us") respects your privacy and is
            committed to protecting your personal information. This Privacy
            Policy explains how we collect, use, store, and delete your data in
            compliance with
            <em>Amazon's Data Protection Policies</em> and other applicable
            regulations.
          </p>

          <section>
            <h2>1. Information We Collect</h2>
            <ul>
              <li>
                <strong>Personally Identifiable Information (PII):</strong>{" "}
                Includes names, email addresses, phone numbers, billing details,
                and shipping information provided by sellers using our services.
              </li>
              <li>
                <strong>Order and Transaction Data:</strong> Order history, tax
                invoices, payment details, and reconciliation data.
              </li>
              <li>
                <strong>Technical and Usage Data:</strong> IP addresses, device
                details, browser type, and interaction logs with our platform.
              </li>
            </ul>
          </section>

          <section>
            <h2>2. How We Use Your Information</h2>
            <ul>
              <li>
                <strong>Providing Services:</strong> To facilitate e-commerce
                automation, including payment reconciliation, tax invoicing, and
                compliance management.
              </li>
              <li>
                <strong>Direct-to-Consumer Shipping:</strong> To process and
                manage shipping logistics for seller orders.
              </li>
              <li>
                <strong>Regulatory Compliance:</strong> To meet financial and
                tax compliance requirements based on regional regulations.
              </li>
              <li>
                <strong>Security & Fraud Prevention:</strong> To safeguard user
                data and prevent fraudulent activities.
              </li>
              <li>
                <strong>Customer Support:</strong> To assist with inquiries and
                service improvements.
              </li>
            </ul>
          </section>

          <section>
            <h2>3. Data Sharing and Disclosure</h2>
            <p>
              We do not sell or rent your personal information. However, we may
              share data under the following circumstances:
            </p>
            <ul>
              <li>
                <strong>With Authorized Third-Party Service Providers:</strong>{" "}
                For processing payments, tax calculations, and shipment
                fulfillment.
              </li>
              <li>
                <strong>With Government and Regulatory Bodies:</strong> If
                required by law or for tax compliance.
              </li>
              <li>
                <strong>With Amazon SP-API:</strong> As necessary to fulfill
                marketplace order and tax processing requirements.
              </li>
            </ul>
          </section>

          <section>
            <h2>4. Data Retention and Deletion Policy</h2>
            <p>
              We retain your data only for as long as required for the purposes
              stated above. Our data deletion process includes:
            </p>
            <ul>
              <li>
                <strong>User-Initiated Deletion:</strong> You may request
                deletion of your account and associated data by contacting{" "}
                <a href="mailto:support@deepecom.com">support@deepecom.com</a>.
                Upon verification, we will delete your data within{" "}
                <em>30 days</em>.
              </li>
              <li>
                <strong>Automatic Data Deletion:</strong> We automatically
                delete inactive user data after <em>X months/years</em> unless
                legally required to retain it.
              </li>
              <li>
                <strong>PII Anonymization:</strong> If immediate deletion is not
                possible due to compliance obligations, we anonymize data to
                prevent identification.
              </li>
              <li>
                <strong>Backup Data:</strong> Encrypted backups are retained for{" "}
                <em>90 days</em> before permanent deletion.
              </li>
            </ul>
          </section>

          <section>
            <h2>5. Data Security Measures</h2>
            <ul>
              <li>
                <strong>End-to-End Encryption:</strong> For all sensitive data
                transfers.
              </li>
              <li>
                <strong>Access Control Mechanisms:</strong> To restrict access
                to authorized personnel only.
              </li>
              <li>
                <strong>Regular Security Audits:</strong> To ensure compliance
                with Amazon and industry standards.
              </li>
            </ul>
          </section>

          <section>
            <h2>6. User Rights and Choices</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access, modify, or delete your personal data.</li>
              <li>Request details on how your data is processed.</li>
              <li>
                Opt out of non-essential data collection and marketing
                communications.
              </li>
            </ul>
            <p>
              For any requests, please contact{" "}
              <a href="mailto:support@deepecom.com">support@deepecom.com</a>.
            </p>
          </section>

          <section>
            <h2>7. Updates to This Policy</h2>
            <p>
              We may update this Privacy Policy periodically to reflect changes
              in our services or legal requirements. We will notify users via
              email or website announcements before changes take effect.
            </p>
          </section>

          <section>
            <h2>8. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy or data
              handling practices, please contact:
            </p>
            <div>
              <strong>DeepEcom Support Team</strong>
              Email:{" "}
              <a href="mailto:support@deepecom.com">support@deepecom.com</a>
              Website:{" "}
              <a
                href="https://deepecom.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                deepecom.com
              </a>
            </div>
          </section>

          <p>
            By using our services, you agree to this Privacy Policy and the
            terms outlined herein.
          </p>
        </div>
        <div
          style={{
            height: "40px",
          }}
        ></div>
      </div>

      <Footer></Footer>
    </>
  );
};

export default Privacy;
